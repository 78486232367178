import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const BottomContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomWrapper = styled.div`
  width: 877px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
`;

const BottomButton = styled.a`
  font-size: 30px;
  line-height: 35px;
  display: flex;
  cursor: pointer;
`;

const BottomLink = styled(Link)`
  font-size: 30px;
  line-height: 35px;
  display: flex;
  cursor: pointer;
`;

const BBtnNumber = styled.div`
  color: #fff;
  width: 30px;
  height: 30px;
  margin-right: 2px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BBtnName = styled.div`
  background-color: #53ff79;
  color: #000000;
  width: 78px;
  height: 30px;
  display: flex;
  align-items: center;
`;

function Bottom() {
  const { t } = useTranslation('bottom');

  return (
    <BottomContainer>
      <BottomWrapper>
        <BottomLink to="/">
          <BBtnNumber>1</BBtnNumber>
          <BBtnName>{t('home')}</BBtnName>
        </BottomLink>
        <BottomButton
          href="https://klayswap.com/exchange/swap"
          rel="noopener noreferrer"
          target="_blank"
        >
          <BBtnNumber>2</BBtnNumber>
          <BBtnName>{t('trade')}</BBtnName>
        </BottomButton>
        <BottomButton
          href="https://xangle.io/project/ISR/recent-disclosure"
          rel="noopener noreferrer"
          target="_blank"
        >
          <BBtnNumber>3</BBtnNumber>
          <BBtnName>{t('ir')}</BBtnName>
        </BottomButton>
        <BottomButton
          href="https://klayswap.com/exchange/pool/detail/0x869440673a24e3c3f18c173d8a964b2f2621245b"
          rel="noopener noreferrer"
          target="_blank"
        >
          <BBtnNumber>4</BBtnNumber>
          <BBtnName>{t('pool')}</BBtnName>
        </BottomButton>
      </BottomWrapper>
    </BottomContainer>
  );
}

export default Bottom;
