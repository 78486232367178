export default {
  ko: 'ko',
  en: 'en',
  title: 'Insureum',
  connetWallet: 'Please connect your wallet to be registered in our network.',
  connet: 'Connet',
  emailAuth: 'Email Authentication',
  emailConfirm: 'Checking Your Email',
  about: 'About',
  supply: 'Total Supply',
  ready: 'Insureum Web 3.0 Ready',
  helloWorld: 'Hello World',
  trade: 'TRADE',
  oldbalance: 'Old token balance',
  newbalance: 'New token balance',
  tokenswap: 'Token swap',
  resend: 'Email ReSend',
  user: {
    wallet: 'wallet',
    klip: 'Kakao Klip',
    kaikas: 'Kaikas',
  },
};
