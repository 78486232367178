import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Bottom from './pages/common/Bottom';
import Header from './pages/common/Header';
import EmailConfirm from './pages/EmailConfirm';
import Home from './pages/Home';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/email-confirm" element={<EmailConfirm />} />
      </Routes>
      <Bottom />
    </>
  );
}

export default App;
