export default {
  connet: {
    title: 'Please Connect Your Wallet',
    klip: 'Kakao Klip Wallet',
    kaikas: 'KaiKas Wallet',
    klipconnet: 'Connect to Kakao Klip via QR Code',
    scan: 'Scan The QR Code',
    desc: 'Open Kakaotalk > Click the search bar > Log in by scanning the code',
    subDesc: 'Klip > Code Scan can be used',
    nonKaikas:
      'Non-Kaikas browser detected. You should consider trying Kaikas!',
  },
  email: {
    auth: 'Please Verify Your Email',
    authButton: 'Authenticate Email',
    error: 'Already Registered Email',
    send: 'Sent You a Confirmation Email',
    confirm: 'Please Click The Confirmation Button In Your Mailbox',
    close: 'Close',
  },
  about: {
    insureumTitle: 'ABOUT INSUREUM',
    insureumContent:
      'The Insureum Protocol uses blockchain to create a decentralized ecosystem that connects insurers, their policyholder, and third parties',
  },
  tokenswap: {
    title: "TOKEN SWAP",
    oldBalance: "Old token balance",
    newBalance: "New token balance",
  }
};
