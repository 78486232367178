export default {
  ko: '한글',
  en: '영어',
  title: '인슈어리움',
  connetWallet: '서비스에 등록하려면, 당신의 지갑을 연동하세요',
  connet: '연결',
  emailAuth: '이메일 인증',
  emailConfirm: '이메일 확인 중',
  about: '정보',
  supply: '총 공급량',
  ready: '인슈어리움 웹 3.0 준비',
  helloWorld: 'Hello World',
  trade: '거래',
  oldbalance: '기존 토큰 수량',
  newbalance: '새 토큰 수량',
  tokenswap: '토큰 교환',
  resend: '다시 보내기',
  user: {
    wallet: '지갑',
    klip: '카카오 클립',
    kaikas: '카이카스',
  },
};
