import dayjs from 'dayjs';

export const localSetItem = (key, data, expiration) => {
  const result = {
    user: data,
    ttl: dayjs().unix() + expiration * 60,
  };

  localStorage.setItem(key, JSON.stringify(result), expiration * 60);
};

export const localGetItem = (keyName) => {
  const data = localStorage.getItem(keyName);

  if (!data) {
    return null;
  }

  const item = JSON.parse(data);

  if (dayjs().unix() > item.ttl) {
    localStorage.removeItem(keyName);
    return null;
  }
  // return data if not expired
  return item;
};
