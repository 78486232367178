/* eslint-disable no-nested-ternary */

import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import HeaderGrid from '../assets/images/headerGrid.png';
import MiddleGrid from '../assets/images/middleGrid.png';
import AboutBackground from '../assets/images/aboutBackground.png';
// import FolderModal from '../components/Modal/FolderModal';
import ConnetWallet from '../components/Modal/ConnetModal';
import TokenSwap from '../components/Modal/TokenSwapModal';

import { localGetItem } from '../utils/handleStorage';
import config from '../config/const';
import FolderModal from '../components/Modal/FolderModal';
import Email from '../components/children/Email';
import AboutModal from '../components/children/AboutModal';

// import caver from '../utils/klaytn/caver';

const MainContainer = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainWrapper = styled.div`
  width: 877px;
  padding-bottom: 48px;
`;

const HeaderSection = styled.div`
  width: 100%;
  height: 219px;
  background: url(${HeaderGrid});
`;

const Title = styled.h1`
  padding: 20px;
  font-size: ${(props) => (props.lng ? '107px' : '103px')};
  line-height: ${(props) => (props.lng ? '89px' : '85px')};
  letter-spacing: 8px;

  :after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 2000ms infinite;
    animation: ellipsis steps(4, end) 2000ms infinite;
    content: '...'; /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 270px;
    }
  }
`;

const MiddleTextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 438px;
  height: 219px;
  padding: 20px;
`;

const MiddleText = styled.span`
  font-size: ${(props) => (props.lng ? '32px' : '28px')};
  line-height: ${(props) => (props.lng ? '38px' : '34px')};
  word-break: keep-all;
`;

const MiddleWalletSection = styled.div`
  width: 439px;
  height: 221px;
  margin-top: -1px;
  margin-bottom: -1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #53ff79;
`;

const Button = styled.button`
  width: 303px;
  height: 60px;
  cursor: pointer;
  background-color: ${(props) => (props.invert ? '#53ff79' : '#000000')};
  border-radius: 50px;
  margin: 6px;

  div {
    width: 303px;
    height: 60px;
    margin-top: -6px;
    background-color: ${(props) => (props.invert ? '#000000' : '#53ff79')};
    border: ${(props) =>
      props.invert ? '1px solid #53ff79' : '1px solid #000000'};
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: ${(props) => (props.lng ? '20px' : '16px')};
      line-height: ${(props) => (props.lng ? '23px' : '19px')};
      color: ${(props) => (props.invert ? '#53ff79' : '#000000')};
    }
  }
`;

const MiddleAboutSection = styled.div`
  background: url(${AboutBackground});
  width: 438px;
  height: 290px;
  border: 1px solid #53ff79;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -1px;
  margin-bottom: -1px;
`;

const MiddleGridSection = styled.div`
  width: 439px;
  height: 146px;
  background: url(${MiddleGrid});
`;

const TradeSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0px 32px 23px;
`;

const RegularText = styled.span`
  font-size: ${(props) => (props.lng ? '26px' : '22px')};
  line-height: ${(props) => (props.lng ? '30px' : '26px')};
`;

const TradeButton = styled.a`
  font-size: ${(props) => (props.lng ? '40px' : '36px')};
  line-height: ${(props) => (props.lng ? '47px' : '43px')};
  text-decoration-line: underline;
  text-align: right;
  cursor: pointer;
`;

function Home() {
  const [walletAddress, setWalletAddress] = useState('');
  const [user, setUser] = useState();

  // modal
  const [connetModal, setConnetModal] = useState(false);
  const [tokenSwapModal, setTokenSwapModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [aboutModal, setAboutModal] = useState(false);

  // Translation
  const { t } = useTranslation('home');
  const { i18n } = useTranslation();

  useEffect(() => {
    const wallet = localGetItem('wallet');

    const getUser = async () => {
      try {
        if (!wallet) return;
        if (!wallet.user.address) return;

        const result = await axios.post(`${config.HOST}/v1/auth/get/user`, {
          address: wallet.user.address,
          wallet: wallet.user.wallet,
        });

        if (result?.data?.data) {
          setUser(result?.data?.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    getUser();
  }, [walletAddress, emailModal]);

  const getKlipAddress = useCallback(async (klip) => {
    try {
      setWalletAddress(klip);
        // const balance = await caver.klay.getBalance(klip);

        // console.log(walletAddress);
        // console.log(balance);
    } catch (e) {
      console.log(e);
    }
  }, []);

  // resend Email
  const reSendEmail = async () => {
    const wallet = localGetItem('wallet');

    try {
      if (!wallet) return;
      if (!user) return;

      const result = await axios.post(`${config.HOST}/v1/auth/resend/email`, {
        email: user.email,
      });

      if (result?.data?.result === 'success') {
        alert('resend Email Success');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <MainContainer>
      <MainWrapper>
        <HeaderSection>
          <Title lng={i18n.language === 'en'}>{t('title')}</Title>
        </HeaderSection>
        <div style={{ display: 'flex' }}>
          <MiddleTextSection>
            {user && user.address ? (
              <>
                <MiddleText>{t('user.wallet')}</MiddleText>
                <MiddleText>/</MiddleText>
                {user.wallet === 'klip' ? (
                  <MiddleText>{t('user.klip')}</MiddleText>
                ) : (
                  <MiddleText>{t('user.kaikas')}</MiddleText>
                )}
                <MiddleText
                  style={{ textDecoration: 'underline' }}
                >{`${user.address.slice(0, 5)}...${user.address.slice(
                  -4,
                )} >`}</MiddleText>
              </>
            ) : (
              <MiddleText lng={i18n.language === 'en'}>
                {t('connetWallet')}
              </MiddleText>
            )}
          </MiddleTextSection>
          <MiddleWalletSection>
            {!user ? (
              <Button
                lng={i18n.language === 'en'}
                onClick={() => setConnetModal(true)}
              >
                <div>
                  <span>{t('connet')}</span>
                </div>
              </Button>
            ) : user && !user.email ? (
              <Button
                lng={i18n.language === 'en'}
                onClick={() => setEmailModal(true)}
              >
                <div>
                  <span>{t('emailAuth')}</span>
                </div>
              </Button>
            ) : user && user.email && user.status ? (
              <MiddleText
                style={{ color: '#000' }}
                lng={i18n.language === 'en'}
              >
                {user.email}
              </MiddleText>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MiddleText
                  style={{ color: '#000', marginBottom: 20 }}
                  lng={i18n.language === 'en'}
                >
                  {t('emailConfirm')}...
                </MiddleText>
                <Button
                  lng={i18n.language === 'en'}
                  onClick={() => reSendEmail()}
                >
                  <div>
                    <span>{t('resend')}</span>
                  </div>
                </Button>
              </div>
            )}
              <Button
                lng={i18n.language === 'en'}
                onClick={() => setTokenSwapModal(true)}
              >
                <div>
                  <span>{t('tokenswap')}</span>
                </div>
              </Button>
          </MiddleWalletSection>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MiddleAboutSection>
              <Button
                invert
                lng={i18n.language === 'en'}
                onClick={() => setAboutModal(true)}
              >
                <div>
                  <span>{t('about')}</span>
                </div>
              </Button>
            </MiddleAboutSection>
            <MiddleGridSection />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MiddleGridSection style={{ marginLeft: -1 }} />
            <TradeSection>
              <RegularText
                style={{ marginBottom: 50 }}
                lng={i18n.language === 'en'}
              >
                {t('supply')} : 290,000,000
              </RegularText>
              <RegularText lng={i18n.language === 'en'}>
                {t('ready')}...
              </RegularText>
              <RegularText lng={i18n.language === 'en'}>/</RegularText>
              <RegularText lng={i18n.language === 'en'}>
                {t('helloWorld')}\n
              </RegularText>
              <TradeButton
                href="https://klayswap.com/exchange/swap"
                rel="noopener noreferrer"
                target="_blank"
                lng={i18n.language === 'en'}
              >
                {t('trade')} {'>'}
              </TradeButton>

            </TradeSection>
          </div>
        </div>
      </MainWrapper>
      <ConnetWallet
        open={connetModal}
        close={() => setConnetModal(false)}
        getKlipAddress={getKlipAddress}
      />

      <TokenSwap
        open={tokenSwapModal}
        close={() => setTokenSwapModal(false)}
      />

      <FolderModal open={emailModal} close={() => setEmailModal(false)}>
        <Email />
      </FolderModal>
      <FolderModal open={aboutModal} close={() => setAboutModal(false)}>
        <AboutModal />
      </FolderModal>
    </MainContainer>
  );
}

export default Home;
