import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

const HeaderContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 77px;
`;

const HeaderWrapper = styled.div`
  width: 877px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const I18nSection = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    cursor: pointer;
    font-size: ${(props) => (props.lng ? '26px' : '20px')};
    line-height: ${(props) => (props.lng ? '30px' : '24px')};
  }

  span {
    font-size: ${(props) => (props.lng ? '26px' : '20px')};
    line-height: ${(props) => (props.lng ? '30px' : '24px')};
    color: #53ff79;
  }
`;

function Header() {
  const { t } = useTranslation('home');
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <I18nSection lng={i18n.language === 'en'}>
          <button
            type="button"
            onClick={() => changeLanguage('ko')}
            style={{ color: i18n.language === 'ko' ? '#53ff79' : '#484F61' }}
          >
            {t('ko')}
          </button>
          <span>/</span>
          <button
            type="button"
            onClick={() => changeLanguage('en')}
            style={{ color: i18n.language === 'en' ? '#53ff79' : '#484F61' }}
          >
            {t('en')}
          </button>
        </I18nSection>
      </HeaderWrapper>
    </HeaderContainer>
  );
}

export default Header;
