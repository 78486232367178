/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */

import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const AboutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 40px;
`;

const Title = styled.h1`
  font-size: ${(props) => (props.lng ? '30px' : '26px')};
  line-height: ${(props) => (props.lng ? '33px' : '29px')};
  margin-bottom: 20px;
`;
const Content = styled.span`
  font-size: ${(props) => (props.lng ? '24px' : '20px')};
  line-height: ${(props) => (props.lng ? '27px' : '23px')};
`;

function AboutModal() {
  const { t } = useTranslation('modal');
  //   const { i18n } = useTranslation();

  return (
    <AboutWrapper>
      <Title>{t('about.insureumTitle')}</Title>
      <Content>{t('about.insureumContent')}</Content>
    </AboutWrapper>
  );
}

export default AboutModal;
