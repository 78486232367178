/* eslint-disable react/prop-types */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { cloneElement } from 'react';

const Modal = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);

  ${(props) =>
    props.open &&
    css`
      display: flex;
      align-items: center;
      /* 팝업이 열릴때 스르륵 열리는 효과 */
      animation: modal-bg-show 0.3s;

      @keyframes modal-bg-show {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `}
`;

const ModalSection = styled.section`
  width: 100%;
  max-width: 746px;
  height: 400px;
  margin: 0 auto;
  background-color: #15141c;
  border: 1px solid #53ff79;
  padding: 40px;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  @keyframes modal-show {
    from {
      opacity: 0;
      margin-top: -50px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }
`;

const CloseButton = styled.button`
  width: 36px;
  height: 36px;
  font-size: 36px;
  color: #53ff79;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
`;

function FolderModal({ open, close, children }) {
  return (
    <Modal open={open}>
      <ModalSection>
        <CloseButton onClick={close}>X</CloseButton>
        {cloneElement(children, { close, open })}
      </ModalSection>
    </Modal>
  );
}

export default FolderModal;
