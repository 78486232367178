import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';

import config from '../config/const';

const MainContainer = styled.main`
  width: 100%;
  min-height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MiddleText = styled.span`
  font-size: ${(props) => (props.lng ? '32px' : '28px')};
  line-height: ${(props) => (props.lng ? '38px' : '34px')};
  word-break: keep-all;
  margin-bottom: 80px;
`;

const StyledLink = styled(Link)`
  width: 540px;
  height: 60px;
  cursor: pointer;
  background-color: #000000;
  border: 1px solid #53ff79;
  border-radius: 50px;

  div {
    width: 540px;
    height: 60px;
    margin-top: -14px;
    background-color: #53ff79;
    /* border: 1px solid #53ff79; */
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: ${(props) => (props.lng === 'true' ? '24px' : '20px')};
      line-height: ${(props) => (props.lng === 'true' ? '27px' : '23px')};
      color: #000000;
    }
  }
`;

function EmailConfirm() {
  const [active, setActive] = useState(false);

  // Translation
  const { t } = useTranslation('emailConfirm');
  const { i18n } = useTranslation();

  const hashEmail = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    const checkEmail = async () => {
      try {
        if (!hashEmail.hashEmail) return;

        const result = await axios.post(
          `${config.HOST}/v1/auth/confirm/email`,
          { hashEmail: hashEmail.hashEmail },
        );

        // console.log(result);
        if (result?.data?.result === 'failed') {
          setActive(false);
        } else {
          setActive(true);
        }
      } catch (e) {
        console.log(e);
      }
    };

    checkEmail();
  }, []);

  return (
    <MainContainer>
      {active ? (
        <>
          <MiddleText lng={i18n.language === 'en'}>{t('confirm')}</MiddleText>
          <StyledLink to="/" lng={String(i18n.language === 'en')}>
            <div>
              <span>{t('home')}</span>
            </div>
          </StyledLink>
        </>
      ) : (
        <>
          <MiddleText lng={i18n.language === 'en'}>{t('faild')}</MiddleText>
          <MiddleText lng={i18n.language === 'en'}>{t('resend')}</MiddleText>
          <StyledLink to="/" lng={String(i18n.language === 'en')}>
            <div>
              <span>{t('home')}</span>
            </div>
          </StyledLink>
        </>
      )}
    </MainContainer>
  );
}

export default EmailConfirm;
