/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from '../../config/const';
import { localGetItem } from '../../utils/handleStorage';

const EmailWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 40px;
`;

const Text = styled.span`
  font-size: ${(props) => (props.lng ? '24px' : '20px')};
  line-height: ${(props) => (props.lng ? '27px' : '23px')};
`;

const StyledForm = styled.form`
  margin-top: ${(props) => (props.error ? '20px' : '80px')};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 540px;
  height: 52px;
  padding: 10px;
  border: 1px solid #53ff79;
  background-color: #15141c;
  color: #53ff79;
  font-size: ${(props) => (props.lng ? '24px' : '20px')};

  :focus {
    outline: none;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    background-color: #15141c;
  }
`;

const Button = styled.button`
  margin-top: 40px;
  width: 540px;
  height: 60px;
  cursor: pointer;
  background-color: #000000;
  border: 1px solid #53ff79;
  border-radius: 50px;

  div {
    width: 540px;
    height: 60px;
    margin-top: -14px;
    background-color: #53ff79;
    /* border: 1px solid #53ff79; */
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: ${(props) => (props.lng ? '24px' : '20px')};
      line-height: ${(props) => (props.lng ? '27px' : '23px')};
      color: ${(props) => (props.invert ? '#53ff79' : '#000000')};
    }
  }
`;

function Email({ close, open }) {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const [error, setError] = useState(false);

  const { t } = useTranslation('modal');
  const { i18n } = useTranslation();

  useEffect(() => {
    setSuccess(false);
    setError(false);
    setEmail('');
  }, [open]);

  const onChangeInput = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const wallet = localGetItem('wallet');

    try {
      const result = await axios.post(`${config.HOST}/v1/auth/send/email`, {
        email,
        address: wallet.user.address,
      });

      if (result?.data?.result === 'success') {
        setSuccess(true);
      } else if (result?.data?.result === 'existing user') {
        setError(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <EmailWrapper>
      {!success ? (
        <>
          <Text lng={i18n.language === 'en'}>{t('email.auth')}</Text>
          {error && (
            <Text style={{ margin: 20 }} lng={i18n.language === 'en'}>
              {t('email.error')}
            </Text>
          )}
          <StyledForm onSubmit={onSubmit} error={error}>
            <StyledInput
              name="email"
              type="email"
              placeholder="email"
              required
              onChange={onChangeInput}
              value={email}
            />
            <Button type="submit">
              <div>
                <span>{t('email.authButton')}</span>
              </div>
            </Button>
          </StyledForm>
        </>
      ) : (
        <>
          <Text style={{ marginBottom: 40 }} lng={i18n.language === 'en'}>
            {t('email.send')}
          </Text>
          <Text style={{ marginBottom: 30 }} lng={i18n.language === 'en'}>
            {t('email.confirm')}
          </Text>
          <Button type="button" onClick={close}>
            <div>
              <span>{t('email.close')}</span>
            </div>
          </Button>
        </>
      )}
    </EmailWrapper>
  );
}

export default Email;
