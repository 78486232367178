/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import Caver from 'caver-js';
import axios from 'axios';
import QRCode from 'qrcode.react';

import { localGetItem } from '../../utils/handleStorage';
import config from '../../config/const';
import caver from "../../utils/klaytn/caver";



const Modal = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);

  ${(props) =>
    props.open &&
    css`
      display: flex;
      align-items: center;
      /* 팝업이 열릴때 스르륵 열리는 효과 */
      animation: modal-bg-show 0.3s;

      @keyframes modal-bg-show {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `}
`;

const ModalSection = styled.section`
  width: 100%;
  max-width: 746px;
  min-height: 400px;
  margin: 0 auto;
  background-color: #15141c;
  border: 1px solid #53ff79;
  padding: 40px;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  @keyframes modal-show {
    from {
      opacity: 0;
      margin-top: -50px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }
`;

const CloseButton = styled.button`
  width: 36px;
  height: 36px;
  font-size: 36px;
  color: #53ff79;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
`;

const TokenSwapContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const QRTitle = styled.h2`
  font-size: ${(props) => (props.lng ? '24px' : '20px')};
  line-height: ${(props) => (props.lng ? '27px' : '23px')};
  margin: 20px 0px 68px 0px;
`;

const Title = styled.h2`
  font-size: ${(props) => (props.lng ? '28px' : '24px')};
  line-height: ${(props) => (props.lng ? '27px' : '23px')};
  margin: 20px 0px 38px 0px;
`;

const RegularText = styled.span`
  font-size: ${(props) => (props.lng ? '22px' : '18px')};
  line-height: ${(props) => (props.lng ? '30px' : '26px')};
`;

const InputText = styled.input`
  font-size: ${(props) => (props.lng ? '26px' : '22px')};
  line-height: ${(props) => (props.lng ? '30px' : '26px')};
  background-color: ${(props) => (props.invert ? '#000000' : '#53ff79')};
  color: ${(props) => (props.invert ? '#53ff79' : '#000000')};
  width: 200px;
`;

const Button = styled.button`
  margin: 6px;
  width: 200px;
  height: 60px;
  cursor: pointer;
  background-color: ${(props) => (props.invert ? '#53ff79' : '#000000')};
  border-radius: 50px;

  div {
    width: 200px;
    height: 60px;
    background-color: ${(props) => (props.invert ? '#000000' : '#53ff79')};
    border: ${(props) =>
      props.invert ? '1px solid #53ff79' : '1px solid #000000'};
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: ${(props) => (props.lng ? '20px' : '16px')};
      line-height: ${(props) => (props.lng ? '23px' : '19px')};
      color: ${(props) => (props.invert ? '#53ff79' : '#000000')};
    }
  }
`;

const ScanText = styled.span`
  font-size: ${(props) => (props.lng ? '28px' : '24px')};
  line-height: ${(props) => (props.lng ? '32px' : '28px')};
`;

const SubDescText = styled.span`
  font-size: ${(props) => (props.lng ? '20px' : '16px')};
  line-height: ${(props) => (props.lng ? '23px' : '19px')};
`;

let caverKai;

function TokenSwapModal({ open, close}) {
  const modalEl = useRef();
  const { t } = useTranslation('modal');
  const { i18n } = useTranslation();

  const [accountAddress, setAccountAddress] = useState('');
  const [oldBalance, setOldBalance] = useState(0);
  const [newBalance, setNewBalance] = useState(0);
  const [swapAmount, setSwapAmount] = useState('');

  const [wallet, setWallet] = useState('');
  const [klipApprove, setKlipApprove] = useState(false);
  const [klipSwap, setKlipSwap] = useState(false);
  const [klipRequestKey, setKlipRequestKey] = useState('');

  const getOldBalance = async (address) => {
	try {
		const oldTokenContract = caver.contract.create(config.OLD_TOKEN_ABI, config.OLD_TOKEN_CONTRACT);
		const balance = await oldTokenContract.call("balanceOf", address);
		setOldBalance(balance);
	} catch(err) {
		console.log(err);
	}
  }

  const getNewBalance = async (address) => {
	try {
		const newTokenContract = caver.contract.create(config.NEW_TOKEN_ABI, config.NEW_TOKEN_CONTRACT);
		const balance = await newTokenContract.call("balanceOf", address);
		setNewBalance(balance);
	} catch(err) {
		console.log(err);
	}
  }

  const onSwapAmountChange = (e) => {
	setSwapAmount(e.target.value)
  }

  const approveTokenKai = async () => {
	try {
		const oldTokenContract = new caverKai.klay.Contract(config.OLD_TOKEN_ABI, config.OLD_TOKEN_CONTRACT)
		await oldTokenContract.methods.approve(config.TOKEN_SWAP_CONTRACT, caverKai.utils.convertToPeb(swapAmount, 'KLAY')).send({from: accountAddress, gas: 800000})
	} catch(err) {
		console.log(err);
	}
  }

  const swapTokenKai = async () => {
	try {
		const swapTokenContract = new caverKai.klay.Contract(config.TOKEN_SWAP_ABI, config.TOKEN_SWAP_CONTRACT)
		await swapTokenContract.methods.tokenSwap(caverKai.utils.convertToPeb(swapAmount, 'KLAY')).send({from: accountAddress, gas: 100000});

		await getOldBalance(accountAddress)
		await getNewBalance(accountAddress)
	} catch(err) {
		console.log(err);
	}
  }

  const approveTokenKlip = async () => {
	try {
		const approveABI = {
			"inputs": [
				{ "internalType": "address", "name": "spender", "type": "address" },
				{ "internalType": "uint256", "name": "amount", "type": "uint256" }
			],
			"name": "approve",
			"outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
			"stateMutability": "nonpayable",
			"type": "function"
		}
		const params = [config.TOKEN_SWAP_CONTRACT, caver.utils.convertToPeb(swapAmount, 'KLAY')]

		const prepare = await axios.post(
			'https://a2a-api.klipwallet.com/v2/a2a/prepare',
		  	{
				bapp: {
			  		name: 'Insureum',
				},
				chain: "klaytn",
				type: "execute_contract",
				transaction: {
					"to": config.OLD_TOKEN_CONTRACT,
					"value": "0",
					"abi": JSON.stringify(approveABI),
					"params": JSON.stringify(params)
				}
		  },
		);

		if (prepare.data.status === 'prepared') {
		  setKlipRequestKey(prepare.data.request_key);
		  setKlipApprove(true);
		}
	  } catch (e) {
		console.log(e);
	  }
  }

  const onGetKlipApproveSuccess = async () => {
    let interval;
    try {
      if (!klipApprove) return;

      const result = await axios.get(
        `https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${klipRequestKey}`,
      );

      if (result?.data?.status === 'completed') {
        setKlipRequestKey('');
        setKlipApprove(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      clearInterval(interval);
    }
  };
  
  useEffect(() => {
    let timer;
    if (klipApprove) {
      timer = setInterval(onGetKlipApproveSuccess, 1000);
    }

    return () => clearInterval(timer);
  }, [klipApprove, onGetKlipApproveSuccess]);

  const swapTokenKlip = async () => {
	try {
		const swapABI = {
			"inputs": [
				{ "internalType": "uint256", "name": "_amount", "type": "uint256" }
			],
			"name": "tokenSwap",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		}
		const params = [caver.utils.convertToPeb(swapAmount, 'KLAY')]

		const prepare = await axios.post(
			'https://a2a-api.klipwallet.com/v2/a2a/prepare',
		  	{
				bapp: {
			  		name: 'Insureum',
				},
				chain: "klaytn",
				type: "execute_contract",
				transaction: {
					"to": config.TOKEN_SWAP_CONTRACT,
					"value": "0",
					"abi": JSON.stringify(swapABI),
					"params": JSON.stringify(params)
				}
		  },
		);

		if (prepare.data.status === 'prepared') {
			setKlipRequestKey(prepare.data.request_key);
			setKlipSwap(true);
		}
	  } catch (e) {
		console.log(e);
	  }
  }

  const onGetKlipSwapSuccess = async () => {
    let interval;
    try {
      if (!klipSwap) return;

      const result = await axios.get(
        `https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${klipRequestKey}`,
      );

      if (result?.data?.status === 'completed') {
        setKlipRequestKey('');
        setKlipSwap(false);
		await getOldBalance(accountAddress)
		await getNewBalance(accountAddress)
      }
    } catch (e) {
      console.log(e);
    } finally {
      clearInterval(interval);
    }
  };
  
  useEffect(() => {
    let timer;
    if (klipSwap) {
      timer = setInterval(onGetKlipSwapSuccess, 1000);
    }

    return () => clearInterval(timer);
  }, [klipSwap, onGetKlipSwapSuccess]);

  useEffect(() => {
    if (open) {
		setSwapAmount(0);
		setKlipApprove(false);
		setKlipSwap(false);
      	setKlipRequestKey('');
		const w = localGetItem("wallet");
		if(!w || !w.user) {
			close();
			alert("Connect wallet first");
			return
		}
		setAccountAddress(w.user.address);
		setWallet(w.user.wallet)

		if(w.user.wallet === "kaikas") {
			caverKai = new Caver(window.klaytn);
		} 
		
		getOldBalance(w.user.address);
		getNewBalance(w.user.address);
	}

	}, [open]);

  return (
    <>
      <Modal open={open}>
        <ModalSection ref={modalEl}>
          <CloseButton onClick={close}>X</CloseButton>
          <TokenSwapContainer>
			{!klipApprove && !klipSwap && (<>
			<Title>{t('tokenswap.title')}</Title>
				<RegularText
				lng={i18n.language === 'en'}
				>
				{wallet}: {accountAddress}
				</RegularText>
				<RegularText
					lng={i18n.language === 'en'}
				>
					{t('tokenswap.oldBalance')} : {oldBalance/1e18}
				</RegularText>
				<RegularText
					lng={i18n.language === 'en'}
				>
					{t('tokenswap.newBalance')} : {newBalance/1e18}
				</RegularText>
				
				<RegularText
					lng={i18n.language === 'en'}
				>
					Swap amount: 
				</RegularText>
				<InputText 
				invert
				onChange={onSwapAmountChange} value={swapAmount} type="number"/>

				{wallet === "kaikas" ? (
					<>
					<Button
						onClick= {approveTokenKai}
					>
					<div>
						<span>Approve</span>
					</div>
					</Button>
					<Button
					onClick={swapTokenKai}
					>
					<div>
						<span>Swap</span>
					</div>
					</Button>
					</>
				) : (
					<>
					<Button
						onClick= {approveTokenKlip}
					>
					<div>
						<span>Approve</span>
					</div>
					</Button>
					<Button
					onClick={swapTokenKlip}
					>
					<div>
						<span>Swap</span>
					</div>
					</Button>
					</>
				)
				}
			</>)
			}

			{klipApprove && (
				<>
                <QRTitle style={{ margin: '20px 0px' }}>
                  Approve tokens with Kakao klip via QR Code
                </QRTitle>
                <div
                  style={{
                    padding: 10,
                    border: '1px solid #53ff79',
                  }}
                >
                  <div
                    style={{
                      padding: 5,
                      backgroundColor: '#fff',
                    }}
                  >
                    <QRCode
                      value={`https://klipwallet.com/?target=/a2a?request_key=${klipRequestKey}`}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 20,
                  }}
                >
                  <ScanText>{t('connet.scan')}</ScanText>
                  <Title style={{ margin: '26px 0px 12px 0px' }}>
                    {t('connet.desc')}
                  </Title>
                  <SubDescText>{t('connet.subDesc')}</SubDescText>
                </div>
				</>
			)}

			{klipSwap && (
				<>
                <QRTitle style={{ margin: '20px 0px' }}>
					Swap tokens with Kakao klip via QR Code
                </QRTitle>
                <div
                  style={{
                    padding: 10,
                    border: '1px solid #53ff79',
                  }}
                >
                  <div
                    style={{
                      padding: 5,
                      backgroundColor: '#fff',
                    }}
                  >
                    <QRCode
                      value={`https://klipwallet.com/?target=/a2a?request_key=${klipRequestKey}`}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 20,
                  }}
                >
                  <ScanText>{t('connet.scan')}</ScanText>
                  <Title style={{ margin: '26px 0px 12px 0px' }}>
                    {t('connet.desc')}
                  </Title>
                  <SubDescText>{t('connet.subDesc')}</SubDescText>
                </div>
				</>
			)}

          </TokenSwapContainer>
        </ModalSection>
      </Modal>
    </>
  );
}

export default TokenSwapModal;
