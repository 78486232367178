/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import axios from 'axios';
import { css } from '@emotion/react';
import { localSetItem } from '../../utils/handleStorage';

const Modal = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);

  ${(props) =>
    props.open &&
    css`
      display: flex;
      align-items: center;
      /* 팝업이 열릴때 스르륵 열리는 효과 */
      animation: modal-bg-show 0.3s;

      @keyframes modal-bg-show {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `}
`;

const ModalSection = styled.section`
  width: 100%;
  max-width: 746px;
  min-height: 400px;
  margin: 0 auto;
  background-color: #15141c;
  border: 1px solid #53ff79;
  padding: 40px;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  @keyframes modal-show {
    from {
      opacity: 0;
      margin-top: -50px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }
`;

const CloseButton = styled.button`
  width: 36px;
  height: 36px;
  font-size: 36px;
  color: #53ff79;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
`;

const ConnetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  font-size: ${(props) => (props.lng ? '24px' : '20px')};
  line-height: ${(props) => (props.lng ? '27px' : '23px')};
  margin: 20px 0px 68px 0px;
`;

const Button = styled.button`
  width: 540px;
  height: 60px;
  cursor: pointer;
  background-color: #000000;
  border: 1px solid #53ff79;
  border-radius: 50px;

  div {
    width: 540px;
    height: 60px;
    margin-top: -14px;
    background-color: #53ff79;
    /* border: 1px solid #53ff79; */
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: ${(props) => (props.lng ? '24px' : '20px')};
      line-height: ${(props) => (props.lng ? '27px' : '23px')};
      color: ${(props) => (props.invert ? '#53ff79' : '#000000')};
    }
  }
`;

const ScanText = styled.span`
  font-size: ${(props) => (props.lng ? '28px' : '24px')};
  line-height: ${(props) => (props.lng ? '32px' : '28px')};
`;

const SubDescText = styled.span`
  font-size: ${(props) => (props.lng ? '20px' : '16px')};
  line-height: ${(props) => (props.lng ? '23px' : '19px')};
`;

function ConnetModal({ open, close, getKlipAddress }) {
  const [klipOpen, setKlipOpen] = useState(false);
  const [klipRequestKey, setKlipRequestKey] = useState('');

  const modalEl = useRef();

  const { t } = useTranslation('modal');
  const { i18n } = useTranslation();

  // const handleClickOutside = ({ target }) => {
  //   if (!modalEl.current.contains(target)) {
  //     console.log('asd');
  //     close();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('click', handleClickOutside);
  //   return () => {
  //     window.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    if (open) {
      setKlipOpen(false);
      setKlipRequestKey('');
    }
  }, [open]);

  // klip
  const handleKlipPrepare = async () => {
    try {
      const prepare = await axios.post(
        'https://a2a-api.klipwallet.com/v2/a2a/prepare',
        {
          bapp: {
            name: 'Insureum',
            // callback: {
            //   success: 'https://www.treasurer.co.kr',
            //   fail: 'https://www.treasurer.co.kr',
            // },
          },
          type: 'auth',
        },
      );

      if (prepare.data.status === 'prepared') {
        setKlipRequestKey(prepare.data.request_key);
        setKlipOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onGetKlipSuccess = async () => {
    let interval;
    try {
      if (!klipOpen) return;

      const result = await axios.get(
        `https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${klipRequestKey}`,
      );

      if (result?.data?.status === 'completed') {
        const klaytnaAddress = result?.data?.result.klaytn_address;
        close();
        // setKlipRequestKey('');
        setKlipOpen(false);
        getKlipAddress(klaytnaAddress);
        localSetItem(
          'wallet',
          { wallet: 'klip', address: klaytnaAddress },
          2022,
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      clearInterval(interval);
    }
  };

  useEffect(() => {
    let timer;
    if (klipOpen) {
      timer = setInterval(onGetKlipSuccess, 1000);
    }

    return () => clearInterval(timer);
  }, [klipOpen, onGetKlipSuccess]);

  // kaikas
  const setAccountInfo = async () => {
    try {
      const { klaytn } = window;
      if (klaytn === undefined) return;

      const account = klaytn.selectedAddress;

      close();
      setKlipRequestKey('');
      setKlipOpen(false);
      getKlipAddress(account);
      localSetItem('wallet', { wallet: 'kaikas', address: account }, 2022);
    } catch (e) {
      console.log(e);
    }
  };

  const loadAccountInfo = async () => {
    const { klaytn } = window;

    if (klaytn) {
      try {
        await klaytn.enable();
        setAccountInfo(klaytn);
        klaytn.on('accountsChanged', () => setAccountInfo(klaytn));
      } catch (error) {
        console.log('User denied account access');
      }
    } else {
      const lng = i18n.language;
      const alertDesc =
        lng === 'en'
          ? 'Non-Kaikas browser detected. You should consider trying Kaikas!'
          : '카이카스 설치 후 다시 이용해주세요';
      alert(alertDesc);
    }
  };

  return (
    <>
      <Modal open={open}>
        <ModalSection ref={modalEl}>
          <CloseButton onClick={close}>X</CloseButton>
          <ConnetContainer>
            {!klipOpen ? (
              <>
                <Title lng={i18n.language === 'en'}>{t('connet.title')}</Title>
                <Button
                  style={{ marginBottom: 54 }}
                  onClick={() => handleKlipPrepare()}
                >
                  <div>
                    <span>{t('connet.klip')}</span>
                  </div>
                </Button>
                <Button onClick={() => loadAccountInfo()}>
                  <div>
                    <span>{t('connet.kaikas')}</span>
                  </div>
                </Button>
              </>
            ) : (
              <>
                <Title style={{ margin: '20px 0px' }}>
                  {t('connet.klipconnet')}
                </Title>
                <div
                  style={{
                    padding: 10,
                    border: '1px solid #53ff79',
                  }}
                >
                  <div
                    style={{
                      padding: 5,
                      backgroundColor: '#fff',
                    }}
                  >
                    <QRCode
                      value={`https://klipwallet.com/?target=/a2a?request_key=${klipRequestKey}`}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 20,
                  }}
                >
                  <ScanText>{t('connet.scan')}</ScanText>
                  <Title style={{ margin: '26px 0px 12px 0px' }}>
                    {t('connet.desc')}
                  </Title>
                  <SubDescText>{t('connet.subDesc')}</SubDescText>
                </div>
              </>
            )}
          </ConnetContainer>
        </ModalSection>
      </Modal>
    </>
  );
}

export default ConnetModal;
