export default {
  connet: {
    title: '지갑을 연동해서 사용할 수 있습니다',
    klip: '카카오 클립 지갑',
    kaikas: '카이카스 지갑',
    klipconnet: '카카오클립 지갑연결',
    scan: 'QR코드를 스캔하세요',
    desc: '카카오톡 > 상단 검색창 > 코드스캔 후 로그인',
    subDesc: '클립 > 코드스캔도 가능합니다',
    notKaikas: '카이카스 설치 후 다시 이용해주세요',
  },
  email: {
    auth: '이메일 인증을 해주세요',
    authButton: '이메일 인증하기',
    error: '이미 등록된 이메일입니다',
    send: '확인 이메일을 보냈습니다',
    confirm: '메일함에서 확인버튼을 눌러주세요',
    close: '닫기',
  },
  about: {
    insureumTitle: '인슈어리움은',
    insureumContent:
      '인슈어리움 프로토콜은 블록체인을 사용하여 보험사, 보험계약자 및 제3자를 연결하는 분산형 생태계를 구축합니다',
  },
  tokenswap: {
    title: "토큰 교환",
    oldBalance: "기존 토큰 잔고",
    newBalance: "새 토큰 잔고",
  }
};
